<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Product and TSR
                                <router-link to="/careers/product-tsr/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We are looking for awesome individuals that have the flair, skill, and experience to understand and provide resolutions to customer concerns and questions via chat. This includes researching for the answers on your own or using your resources on hand, and also being able to think outside of the box and critically. </p>
                                <p>At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you! </p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Core Responsibilities:
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Respond to inbound chat conversations (chat).</li>
                                            <li><i class="icon-ok"></i>Be the voice of the customer; give direct feedback and updates on bug and feature requests.</li>
                                            <li><i class="icon-ok"></i>Be able to use their CRM, SaaS, and Salesforce experience to show how to navigate and answer questions around it.</li>
                                            <li><i class="icon-ok"></i>Be able to upsell additional services and make permanent customers.</li>
                                            <li><i class="icon-ok"></i>Route appropriate tickets to CS, Finance, Legal, or Sales</li>
                                            <li><i class="icon-ok"></i>Accurately categorize and document each interaction.</li>
                                            <li><i class="icon-ok"></i>Occasionally help customers live over Zoom or phone call</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Minimum Requirements/experience needed: 
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Proven work experience as a technical support representative, or a similar role. 2 years of work experience in the same field is a plus. </li>
                                            <li><i class="icon-ok"></i>Hands-on experience with CRM/SaaS applications.</li>
                                            <li><i class="icon-ok"></i>Familiarity with Zapier and or similar applications. </li>
                                            <li><i class="icon-ok"></i>Familiarity with Reporting/Excel/Google Sheets.</li>
                                            <li><i class="icon-ok"></i>Familiarity with chat apps like Intercom, Slack,</li>
                                            <li><i class="icon-ok"></i>Excellent verbal and written communication skills. </li>
                                            <li><i class="icon-ok"></i>Good time-management skills with the ability to handle various open tickets/ chats simultaneously. </li>
                                            <li><i class="icon-ok"></i>Strong and keen attention to detail. </li>
                                            <li><i class="icon-ok"></i>Has chat support experience.</li>
                                            <li><i class="icon-ok"></i>Has experience with upselling the product to the customer.</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary.</li>
                                            <li><i class="icon-plus-sign"></i>Work-from-home setup as we prepare to provide a safe environment for our employees. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>We offer comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/product-tsr/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    }
}
</script>